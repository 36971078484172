package dev.scorbett123.kotlinGame.engine.gui

import com.danielgergely.cgmath.graphics.translateMatrix
import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec2.plus
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec4.Vec4
import dev.scorbett123.kotlinGame.engine.`object`.Vertex
import dev.scorbett123.kotlinGame.engine.render.Mesh
import dev.scorbett123.kotlinGame.engine.render.texture.Image
import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import kotlinx.datetime.Clock
import kotlin.math.ceil
import kotlin.math.max

class TextPanel(val text: String, val dx: Int, val dy: Int, val width: Int, var height: Int,  val parent: Component, var fontSize: Float = 13f, val bold: Boolean = false) { // fontSize 16 means that each is 16 pixels wide, 32 high

    val bakedMesh: Mesh

    val cols = 16
    val rows = 16

    val textColour
    get() = Vec4(1.0f, 1.0f, 1.0f, (Clock.System.now().toEpochMilliseconds() - parent.lastShowTime).toFloat() / 300)

    var scroll: Float = 0f
        set(value) {
            println(actualHeight - height)
            field = if ((value < 0)) {
                0f
            } else if ((value > actualHeight - height)) {
                actualHeight - height
            } else {
                value
            }
        }


    var currentRow = 0
    var currentCol = 0

    val actualHeight: Float
        get() = (currentRow + 1) * fontSize * 2


    fun getTranslateMatrix(): Mat4 {
        return translateMatrix(Vec3(parent.x.toFloat() + dx, parent.y.toFloat() + scroll + parent.height + dy, 0.toFloat()))
    }

    init {
        val string = text.toCharArray().map { it.code }

        val indices = ArrayList<Int>()
        val vertices = ArrayList<Vertex>()


        var currentX = 0
        var startCol = 0

        for (cWi in string.withIndex()) {
            if (cWi.value == 10) {
                currentRow += 1
                startCol = 0
                currentCol = startCol
            }

            if (cWi.value == 9) {
                startCol += 4
                currentCol += startCol
            }

            if (cWi.value < 32) {
                continue
            }

            val char = cWi.value
            val iSize = vertices.size
            indices.addAll(arrayOf(iSize, iSize + 1, iSize + 2,
            iSize, iSize + 2, iSize + 3))

            val charWidth = fontSize

            val row = char / cols
            val col = char % cols

            val y = 0 - (currentRow + 1) * fontSize * 2 - GUI.cornerRadius
            val x = currentCol * fontSize + GUI.cornerRadius

            val amount = 1 / cols.toFloat()
            val texCoord = Vec2(col * amount, (row.toFloat() + 1) * amount)


            vertices.addAll(arrayOf(
                Vertex(Vec3(x, y, 0f), texCoord),
                Vertex(Vec3(x, y + fontSize * 2, 0f), texCoord + Vec2(0f, -amount)),
                Vertex(Vec3(x + fontSize, y + fontSize * 2, 0f), texCoord + Vec2(amount, -amount)),
                Vertex(Vec3(x + fontSize, y, 0f), texCoord + Vec2(amount, 0f))
            ))
            currentCol += 1

            if ((currentCol + text.amountToNextSpace(cWi.index)) * fontSize + GUI.cornerRadius * 2 > width - 10) {
                currentCol = startCol
                currentRow += 1
            }
        }

        bakedMesh = Mesh(vertices.toTypedArray(), indices.toTypedArray())
    }

    fun String.amountToNextSpace(current:Int): Int {
        if (current + 1 >= this.length){
            return 0
        }

        var x = 1

        while (this[x + current] != ' ') {
            x += 1

            if (x + current >= this.length){
                break
            }
        }
        return x
    }
}
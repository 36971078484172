package dev.scorbett123.kotlinGame

import com.danielgergely.cgmath.graphics.perspective
import com.danielgergely.cgmath.mat4.Mat4
import dev.scorbett123.kotlinGame.engine.`object`.*
import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.engine.IO.IO
import dev.scorbett123.kotlinGame.engine.IO.Mouse
import dev.scorbett123.kotlinGame.engine.gui.GuiRenderer
import dev.scorbett123.kotlinGame.engine.`object`.animation.Animation
import dev.scorbett123.kotlinGame.engine.`object`.objects.Piping
import dev.scorbett123.kotlinGame.game.objects.Terrain
import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import kotlinx.datetime.Clock

object Game {


    val projection = Mat4.identity()

    val aspectRatio
    get() = IO.getScreenWidth().toFloat() / IO.getScreenHeight().toFloat()
    val fov = 60f

    fun start() {
        Mouse
        // CommunicationManager
        GL.enable(GL.DEPTH_TEST)
        GL.enable(GL.BLEND)
        //GL.enable(GL.SCISSOR_TEST)

        GL.blendFunc(GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA)

        perspective(fov, aspectRatio, 0.1f, 1000f,projection)

        Terrain

        JsonLoader.loadMap()
        Piping.loadPipes()
    }

    var lastFrameTime = getCurrentTimeMillis()
    val frameRate = 60
    val wantedMillisPerFrame = 1000f / frameRate

    fun runFrame(): Boolean {
        var millisPerFrame = (getCurrentTimeMillis() - lastFrameTime).toFloat()
        while (millisPerFrame < wantedMillisPerFrame) {
            millisPerFrame = (getCurrentTimeMillis() - lastFrameTime).toFloat()
        }
        lastFrameTime = getCurrentTimeMillis()

        perspective(fov, aspectRatio, 0.1f, 2000f,projection)

        if (IO.shouldClose()) {
            IO.shutdown()
            return false
        }

        GL.clearColour(230.0f / 255, 186.0f / 255, 115.0f / 255, 1.0f)
        GL.clear(GL.COLOR_BUFFER_BIT or GL.DEPTH_BUFFER_BIT)

        GameObjects.renderableObjects.forEach {
            it.render(projection)
        }

        GameObjects.objects.forEach {
            it.update()
        }

        GuiRenderer.render()

//        CommunicationManager.processPackets()
        IO.pollEvents()
        GL.postRender()
        return true
    }

    fun getCurrentTimeMillis(): Long {
        return Clock.System.now().toEpochMilliseconds()
    }
}
package dev.scorbett123.kotlinGame.engine.physics

import com.danielgergely.cgmath.vec3.Vec3
import kotlin.math.max
import kotlin.math.min

class BoundingBox(val a: Vec3, val b: Vec3) {

    val maxX = max(a.x, b.x)
    val maxY = max(a.y, b.y)
    val maxZ = max(a.z, b.z)

    val minX = min(a.x, b.x)
    val minY = min(a.y, b.y)
    val minZ = min(a.z, b.z)

    fun doesCollide(other: BoundingBox): Boolean {
        return ((this.minX <= other.maxX) and (this.maxX >= other.minX)) and
                ((this.minY <= other.maxY) and (this.maxY >= other.minY))and
                ((this.minZ <= other.maxZ) and (this.maxZ >= other.minZ))
    }

    companion object {
        val EMPTY = BoundingBox(Vec3(0f, 0f, 0f), Vec3(0f, 0f, 0f))
    }
}
package dev.scorbett123.kotlinGame

import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.engine.IO.IO
import dev.scorbett123.kotlinGame.engine.`object`.JsonLoader
import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import kotlinx.browser.window
import kotlinx.serialization.Serializable
import kotlin.js.Date

fun main() {
   console.log("hello")
   logDebug("test")
   GL
   IO.init()
   console.log("starting")
   Game.start()

   frame()
}

val d = Date()
var lastFrame = d.getTime() - 1

fun frame() {
    window.requestAnimationFrame {
        val d = Date()


        lastFrame = d.getTime() - 1;

        if (Game.runFrame()) {
            frame()
        }
    }
}

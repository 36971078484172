package dev.scorbett123.kotlinGame.platformSpecific

import dev.scorbett123.kotlinGame.platformSpecific.LogLevel

internal actual fun writeLogMessage(vararg message: Any, logLevel: LogLevel) {

    val m = message.joinToString(" ")
    when (logLevel) {
        LogLevel.DEBUG -> console.log(m)
        LogLevel.WARN -> console.warn(m)
        LogLevel.ERROR -> console.error(m)
    }
}
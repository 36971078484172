package dev.scorbett123.kotlinGame.engine.`object`

import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.vec3.Vec3
import dev.scorbett123.kotlinGame.engine.physics.BoundingBox
import dev.scorbett123.kotlinGame.engine.render.Material
import dev.scorbett123.kotlinGame.engine.render.Mesh
import dev.scorbett123.kotlinGame.engine.render.Renderer

open class RenderObject(val mesh: Mesh, val box: BoundingBox, val material: Material, addToRenderingObjects: Boolean = true): Object() {


    init {
        if (addToRenderingObjects) {
            GameObjects.renderableObjects.add(this)
        }
    }

    open fun render(projectionMatrix: Mat4) {
        Renderer.render(this, projectionMatrix)
    }

    fun copy(addToRenderingObjects: Boolean = true): RenderObject {
        val new = RenderObject(mesh, box, material, addToRenderingObjects)

        new.scale = Vec3(this.scale.x, this.scale.y, this.scale.z)
        new.position = Vec3(this.position.x, this.position.y, this.position.z)
        new.rotation = Vec3(this.rotation.x, this.rotation.y, this.rotation.z)

        return new
    }
}
package dev.scorbett123.kotlinGame.engine.IO

import com.danielgergely.cgmath.vec2.Vec2
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ClickEvent
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ScrollEvent
import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import kotlinx.browser.window
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.WheelEvent

actual object IO {
    val keyboard = HashMap<String, Boolean>()
    var mouseX = 0
    var mouseY = 0

    fun init() {
        window.addEventListener("keydown", {
            val keyEvent = it as KeyboardEvent
            keyboard[keyEvent.key.lowercase()] = true
            if (!(keyEvent.ctrlKey and (keyboard["shift"] == true))) {
                keyEvent.preventDefault()
            }
        })
        window.addEventListener("keyup", {
            val keyEvent = it as KeyboardEvent
            keyboard[keyEvent.key.lowercase()] = false
            if (!(keyEvent.ctrlKey and (keyboard["shift"] == true))) {
                keyEvent.preventDefault()
            }
        })

        GL.canvas.addEventListener("mousedown", {
            val clickEvent = it as MouseEvent

            EventManager.processClickEvent(ClickEvent(Vec2(clickEvent.x.toFloat(), getScreenHeight() - clickEvent.y.toFloat()), ClickEvent.Action.PRESS))
        })

        GL.canvas.addEventListener("mouseup", {
            val clickEvent = it as MouseEvent
            EventManager.processClickEvent(ClickEvent(Vec2(clickEvent.x.toFloat(), getScreenHeight() - clickEvent.y.toFloat()), ClickEvent.Action.RELEASE))
        })

        GL.canvas.addEventListener("mouseleave", {
            val clickEvent = it as MouseEvent
            EventManager.processClickEvent(ClickEvent(Vec2(clickEvent.x.toFloat(), getScreenHeight() - clickEvent.y.toFloat()), ClickEvent.Action.RELEASE))
        })

        GL.canvas.addEventListener("contextmenu", {
            it.preventDefault()
        })

        GL.canvas.addEventListener("mousemove", {
            val event = it as MouseEvent

            mouseX = event.x.toInt()
            mouseY = getScreenHeight() - event.y.toInt()
        })

        GL.canvas.addEventListener("wheel", {
            val wheelEvent = it as WheelEvent
            EventManager.processScrollEvent(ScrollEvent(Vec2(wheelEvent.x.toFloat(), getScreenHeight() - wheelEvent.y.toFloat()), -wheelEvent.deltaY / 100))
        })
    }
    actual fun shouldClose(): Boolean {
        return false
    }

    actual fun pollEvents() {

    }

    actual fun shutdown() {
    }

    actual fun isKeyDown(key: String): Boolean {
        return keyboard[key]?: false
    }

    actual fun getScreenWidth(): Int {
        return GL.canvas.width
    }

    actual fun getScreenHeight(): Int {
        return GL.canvas.height
    }

    actual fun getMouseX(): Int {
        return mouseX
    }

    actual fun getMouseY(): Int {
        return mouseY
    }
}
package dev.scorbett123.kotlinGame.engine.render.shaders

import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec4.Vec4
import dev.scorbett123.kotlinGame.engine.ResourceManager
import dev.scorbett123.kotlinGame.engine.ResourceType
import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import dev.scorbett123.kotlinGame.platformSpecific.logError
import org.khronos.webgl.WebGLProgram
import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLShader

actual class ShaderProgram actual constructor(frag: String, vert: String) {
    val programId: WebGLProgram? = GL.webgl.createProgram()

    init {
        val vs = genShader(ResourceManager.loadResourceAsString(ResourceType.SHADER, vert), GL.VERTEX_SHADER)
        val fs = genShader(ResourceManager.loadResourceAsString(ResourceType.SHADER, frag), GL.FRAGMENT_SHADER)

        GL.webgl.attachShader(programId, vs)
        GL.webgl.attachShader(programId, fs)
        GL.webgl.linkProgram(programId)

        GL.webgl.validateProgram(programId)
        if (GL.webgl.getProgramParameter(programId, WebGLRenderingContext.VALIDATE_STATUS) == false) {
            logError("info: " + GL.webgl.getProgramInfoLog(programId))
        }
        GL.webgl.useProgram(programId)

    }

    fun genShader(source: String, type: Int): WebGLShader? {
        val id = GL.webgl.createShader(type)
        GL.webgl.shaderSource(id, source)
        GL.webgl.compileShader(id)

        if (GL.webgl.getShaderParameter(id, WebGLRenderingContext.COMPILE_STATUS) == false) {
            logError("Failed to compile shader due to failure reason: ${GL.webgl.getShaderInfoLog(id)}")
        }
        return id
    }

    actual fun setMatrix4f(name: String, mat4: Mat4) {
        val position = GL.webgl.getUniformLocation(programId, name)
        GL.webgl.uniformMatrix4fv(position, false, mat4.array.toTypedArray())
    }

    actual fun apply() {
        GL.webgl.useProgram(programId)
    }

    actual fun setFloat(name: String, float: Float) {
        val position = GL.webgl.getUniformLocation(programId, name)
        GL.webgl.uniform1f(position, float)
    }

    actual fun setVec4(name: String, vec4: Vec4) {
        val position = GL.webgl.getUniformLocation(programId, name)
        GL.webgl.uniform4fv(position, vec4.array.toTypedArray())
    }

    actual fun setVec3(name: String, vec3: Vec3) {
        val position = GL.webgl.getUniformLocation(programId, name)
        GL.webgl.uniform3fv(position, vec3.array.toTypedArray())
    }

    actual fun setVec2(name: String, vec2: Vec2) {
        val position = GL.webgl.getUniformLocation(programId, name)
        GL.webgl.uniform2fv(position,  vec2.array.toTypedArray())
    }

}
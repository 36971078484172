package dev.scorbett123.kotlinGame.engine.IO

import dev.scorbett123.kotlinGame.engine.IO.callbacks.ClickEvent
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ScrollEvent
import dev.scorbett123.kotlinGame.engine.MyMap
import dev.scorbett123.kotlinGame.engine.gui.GUI
import dev.scorbett123.kotlinGame.engine.`object`.Camera
import kotlin.math.floor

object EventManager {

    fun processClickEvent(clickEvent: ClickEvent) {
        if (GUI.shouldOverride(clickEvent.position)) {
            GUI.handleClick(clickEvent)
        } else {
            if (clickEvent.action == ClickEvent.Action.PRESS) {
                val pos = Mouse.screenSpaceToWorldSpace(clickEvent.position)
                val ob = MyMap.getXY(floor(pos.x).toInt(), floor(pos.z).toInt())
                if (ob == null) {
                    GUI.clearComponents()
                } else {
                    GUI.clearComponents()
                    GUI.showComponent(ob.basicComponentWithText)
                }
            }
        }
    }

    fun processScrollEvent(scrollEvent: ScrollEvent) {
        if (GUI.shouldOverride(scrollEvent.position)) {
            GUI.handleScroll(scrollEvent)
        } else {
            Camera.position.y += -(Camera.delta * scrollEvent.amount).toFloat() * 10
        }
    }
}
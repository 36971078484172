package dev.scorbett123.kotlinGame.engine.IO

import com.danielgergely.cgmath.mat4.times
import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec3.normalize
import com.danielgergely.cgmath.vec4.Vec4
import com.danielgergely.cgmath.vec4.xyz
import dev.scorbett123.kotlinGame.Game
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ClickEvent
import dev.scorbett123.kotlinGame.engine.`object`.Camera
import dev.scorbett123.kotlinGame.platformSpecific.logDebug

object Mouse {

    init {

    }

    fun screenSpaceToWorldSpace(screenSpace: Vec2): Vec3 {
        val x: Float = 2.0f * screenSpace.x / IO.getScreenWidth() - 1.0f
        val y: Float = 2.0f * screenSpace.y / IO.getScreenHeight() - 1.0f
        val z = 1.0f
        val ray_nds = Vec3(x, y, z)

        val ray_clip = Vec4(ray_nds.x, ray_nds.y, -1.0f, 1.0f)

        val invertedProjection = Game.projection.copy()
        invertedProjection.invert()

        val ray_eye: Vec4 = invertedProjection * ray_clip
        ray_eye.z = -1.0f
        ray_eye.w = 0.0f

        val invertedView = Camera.positionMatrix
        invertedView.invert()

        val ray_wor = (invertedView * ray_eye).xyz
        ray_wor.normalize()

//        println(ray_wor)
        val dist = Camera.position.y / (-ray_wor.y)

        val resultX = (Camera.position.x + (ray_wor.x * dist))
        val resultZ = (Camera.position.z + (ray_wor.z * dist))
//        logDebug(Camera.position)
//        logDebug(resultX, resultZ)
        return Vec3(resultX,0f, resultZ)
    }
}
package dev.scorbett123.kotlinGame.engine.gui

import com.danielgergely.cgmath.vec2.Vec2
import dev.scorbett123.kotlinGame.engine.IO.IO
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ClickEvent
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ScrollEvent
import kotlinx.datetime.Clock

object GUI {



    // always add components to the end so that they are rendered last, therefore on top of components underneath
    val components = ArrayList<Component>()
    var cornerRadius = 20f
    var borderWidth = 2f

    val instructionsPanel = BasicComponentWithText("Instructions", """
        |Keys
        |W - Forwards
        |S - Backwards
        |A - Left
        |D - Right
        |Shift - Up
        |Ctrl - Down
        |
        |Click on objects to view a description of their purpose.
        |
    """.trimMargin(), width = 400)

    init {
        instructionsPanel.x = 0
        instructionsPanel.y = IO.getScreenHeight() - instructionsPanel.height
        showComponent(instructionsPanel)
    }

    fun clearComponents() {
        components.clear()
        showComponent(instructionsPanel, false)
    }

    fun showComponent(component: Component, updateShowTime: Boolean = true) {
        if (updateShowTime) {
            component.lastShowTime = Clock.System.now().toEpochMilliseconds()
        }
        components.add(component)
    }

    fun shouldOverride(pos: Vec2): Boolean {
        if (components.any { it.wantsFullControl() }) {
            return true
        }
        return components.any { it.posWithin(pos) }
    }

    fun Component.posWithin(pos: Vec2): Boolean {
        return (pos.x > this.x) and (pos.y > this.y) and (pos.x - this.x < width) and (pos.y - this.y < height)
    }

    fun handleClick(event: ClickEvent) {
        if (components.any { it.wantsFullControl() }) {
            components.first { it.wantsFullControl() }.onClick(event)
            return
        }

        components.last { it.posWithin(event.position) }.onClick(event)
    }

    fun handleScroll(event: ScrollEvent) {
        if (components.any { it.wantsFullControl() }) {
            components.first { it.wantsFullControl() }.onScroll(event)
            return
        }

        components.last { it.posWithin(event.position) }.onScroll(event)
    }
}
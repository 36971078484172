package dev.scorbett123.kotlinGame.engine.`object`.objects

import dev.scorbett123.kotlinGame.engine.gui.BasicComponentWithText
import dev.scorbett123.kotlinGame.engine.`object`.RenderObject
import dev.scorbett123.kotlinGame.engine.physics.BoundingBox
import dev.scorbett123.kotlinGame.engine.render.Material
import dev.scorbett123.kotlinGame.engine.render.Mesh

class ClickableObject(mesh: Mesh, boundingBox: BoundingBox, material: Material, val x: Int, val y: Int, val width: Int, val height: Int, private val pipeX: Int, private val pipeY: Int, val title: String, val description: String): RenderObject(mesh, boundingBox, material){

    val basicComponentWithText by lazy {
        BasicComponentWithText(title, description)
    }

    val actualPipeX: Int
    get() = this.x + pipeX

    val actualPipeY: Int
        get() = this.y + pipeY

    init {
        this.position.x = x.toFloat()
        this.position.z = y.toFloat()
    }
}
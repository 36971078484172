package dev.scorbett123.kotlinGame.engine

import dev.scorbett123.kotlinGame.engine.`object`.objects.ClickableObject
import dev.scorbett123.kotlinGame.engine.`object`.objects.Piping
import dev.scorbett123.kotlinGame.platformSpecific.logDebug

object MyMap {

    private val map: HashMap<Int, ClickableObject> = HashMap()
    private val isPipe: HashMap<Int, Boolean> = HashMap()

    var xMax = 0
    var yMax = 0
    var xMin = 0
    var yMin = 0

    fun updateWidthHeight(x: Int, y: Int) {
        if (x > xMax) {
            xMax = x
        }
        if (y > yMax) {
            yMax = y
        }

        if (x < xMin) {
            xMin = x
        }
        if (y < yMin) {
            yMin = y
        }
    }

    fun getIsPipe(x: Int, y: Int): Boolean {
        return isPipe[hash(x, y)] ?: false
    }

    fun isPipeConnection(x: Int, y: Int): Boolean {
        if (isPipe[hash(x, y)] == true) {
            return true
        }

        if ((map[hash(x, y)]?.actualPipeX ?: -1) == -1) {
            return false
        }

        return (map[hash(x, y)]?.actualPipeX == x) && (map[hash(x, y)]?.actualPipeY == y)
    }

    fun setIsPipe(x: Int, y: Int, boolean: Boolean) {
        updateWidthHeight(x, y)
        isPipe[hash(x, y)] = boolean
    }

    fun getXY(x: Int, y: Int): ClickableObject? {
        logDebug(x, y)
        if (isPipe[hash(x, y)] == true) {
            return Piping.pipingRenderObject
        }
        return map[hash(x, y)]
    }

    fun setXY(x: Int, y: Int, clickable: ClickableObject) {
        map[hash(x, y)] = clickable
    }

    fun addObject(clickable: ClickableObject) {
        updateWidthHeight(clickable.x + clickable.width, clickable.y + clickable.height)
        for (x in 0 until clickable.width) {
            for (y in 0 until clickable.height) {
                map[hash(clickable.x + x, clickable.y + y)] = clickable
            }
        }
    }

    fun hash(x: Int, y: Int): Int {
        return (x shl 16) + y
    }
}
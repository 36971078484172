package dev.scorbett123.kotlinGame.engine

import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import org.w3c.xhr.XMLHttpRequest

actual object ResourceManager {
    actual fun loadResourceAsString(
        resourceType: ResourceType,
        name: String
    ): String {
        val fileName = "/${resourceType.toString().lowercase()}/${name}"
        var req = XMLHttpRequest()
        req.open("GET", fileName, false)
        req.send(null)
        return req.responseText
    }
}
package dev.scorbett123.kotlinGame.engine.gui

import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import dev.scorbett123.kotlinGame.engine.IO.IO
import dev.scorbett123.kotlinGame.engine.`object`.Vertex
import dev.scorbett123.kotlinGame.engine.render.Mesh
import dev.scorbett123.kotlinGame.engine.render.shaders.ShaderProgram
import dev.scorbett123.kotlinGame.engine.render.texture.Image
import dev.scorbett123.kotlinGame.platformSpecific.GL

object GuiRenderer {

    val curvedShader = ShaderProgram("guiFrag.glsl", "guiVert.glsl")

    val fontImage = Image("font", "font")

    val toRender = Mesh(arrayOf(Vertex(Vec3(0f, 0f, 0f), Vec2(0f, 0f)),
        Vertex(Vec3(0f, 1f, 0f), Vec2(0f, 1f)),
        Vertex(Vec3(1f, 1f, 0f), Vec2(1f, 1f)),
        Vertex(Vec3(1f, 0f, 0f), Vec2(1f, 0f))),
    arrayOf(0, 1, 2,
    0, 2, 3)
    )

    var perspective = ortho(IO.getScreenWidth().toFloat(), IO.getScreenHeight().toFloat())


    fun render() {
        GL.disable(GL.DEPTH_TEST)
        perspective = ortho(IO.getScreenWidth().toFloat(), IO.getScreenHeight().toFloat())
        for (component in GUI.components) {
            component.tick()

            toRender.apply()
            curvedShader.apply()

            curvedShader.setFloat("cornerRadius", GUI.cornerRadius)
            curvedShader.setFloat("borderWidth", GUI.borderWidth)

            curvedShader.setVec2("dimensions", Vec2(component.width.toFloat(), component.height.toFloat()))
            curvedShader.setVec4("fillColour", component.fillColour)
            curvedShader.setVec4("borderColour", component.borderColour)

            curvedShader.setMatrix4f("perspective", perspective)
            curvedShader.setMatrix4f("object", component.getMatrix())

            GL.drawElements(GL.TRIANGLES, toRender.indices.size, GL.UNSIGNED_INT)

            component.postrender()
        }

        GL.enable(GL.DEPTH_TEST)
    }

    fun ortho(width: Float, height: Float): Mat4 {
        val OrthoMatrix = Mat4.identity()

        val zNear = 1f
        val zFar = -1f

        OrthoMatrix[0,0] = 2 / width
        OrthoMatrix[1,1] = 2 / height
        OrthoMatrix[2,2]= -2 / (zFar - zNear)

        OrthoMatrix[3,0] = -1f
        OrthoMatrix[3,1] = -1f

        OrthoMatrix[3, 2] = -(zFar + zNear) / (zFar - zNear)

        OrthoMatrix[3,3] = 1f
        return OrthoMatrix
    }

}
package dev.scorbett123.kotlinGame.engine.gui

import com.danielgergely.cgmath.vec4.Vec4
import dev.scorbett123.kotlinGame.engine.IO.IO
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ClickEvent
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ScrollEvent
import kotlinx.datetime.Clock
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.min

class BasicComponentWithText(title: String, content: String, override var width: Int = 800, override var height: Int = IO.getScreenHeight() - 200) : Component() {

    override var x: Int  = 100
    override var y: Int  = 100
    override var fillColour: Vec4 = Vec4(0f, 0f, 1f, 0.7f)
    override var borderColour: Vec4 = Vec4(0f, 0f, 1f, 1f)

    val text = TextPanel(content, 0, -30, width, (height-30-2*GUI.cornerRadius).toInt(), this)
    val title = TextPanel(title, 0, 0, width, height, this, fontSize = 20f, bold = true)

    init {
        if (text.actualHeight + 25 < height) {
            this.height = ceil(max(text.actualHeight + 25 + GUI.cornerRadius * 2, GUI.cornerRadius * 3)).toInt()

            text.height = text.actualHeight.toInt()
        }
    }

    override fun postrender() {
        super.postrender()
        FontRenderer.renderText(title, this)
        FontRenderer.renderText(text,  this)
    }

    override fun onScroll(event: ScrollEvent) {
        super.onScroll(event)
        text.scroll += (-event.amount * 50).toFloat()
    }

    var isScrollClicked = false

    override fun onClick(event: ClickEvent) {
        super.onClick(event)
        if (event.position.x > this.x + width - GUI.cornerRadius - 10) {
            val barSize = ((height - 2 * GUI.cornerRadius) / text.actualHeight) * height
            val start = (text.scroll / text.actualHeight) * height

            if ((event.position.y - y < this.height - start) and (event.position.y - y > (this.height - start) - barSize)) {
                isScrollClicked = event.action == ClickEvent.Action.PRESS
            }
        }

        if (event.action == ClickEvent.Action.RELEASE) {
            isScrollClicked = false
        }
    }

    override fun wantsFullControl(): Boolean {
        return isScrollClicked
    }

    override fun tick() {
        if (isScrollClicked) {
            text.scroll = (this.height - GUI.cornerRadius - (IO.getMouseY() - this.y)).toFloat() / (this.height - GUI.cornerRadius) * this.text.actualHeight
        }
    }
}

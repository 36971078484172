package dev.scorbett123.kotlinGame.engine.`object`

import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import dev.scorbett123.kotlinGame.engine.ResourceManager
import dev.scorbett123.kotlinGame.engine.ResourceType
import dev.scorbett123.kotlinGame.engine.physics.BoundingBox
import dev.scorbett123.kotlinGame.engine.render.Material
import dev.scorbett123.kotlinGame.engine.render.Mesh
import dev.scorbett123.kotlinGame.engine.render.shaders.ShaderProgram
import dev.scorbett123.kotlinGame.engine.render.texture.Texture

object ObjLoader {

    fun loadObjAsMeshInfo(stream: String): MeshInfo {
        val vertices = arrayListOf<Vertex>()
        val indices = arrayListOf<Int>()

        val positions = arrayListOf<Vec3>()
        val textures = arrayListOf<Vec2>()
        val normals = arrayListOf<Vec3>()
        for(line in stream.split("\n")) {
            val values = line.split(" ").drop(1)
            when (line.split(" ")[0].trim()) {
                "v" -> {
                    val vs = values.map { it.toFloat() }.toFloatArray()
                    positions.add(Vec3(vs[0], vs[1], vs[2]))
                }
                "vt" -> {
                    val vs = values.map { it.toFloat() }.toFloatArray()
                    textures.add(Vec2(vs[0], 1-vs[1]))
                }
                "vn" -> {
                    val vs = values.map { it.toFloat() }.toFloatArray()
                    normals.add(Vec3(vs[0], vs[1], vs[2]))
                }
                "f" -> {
                    for (i in values) {
                        val x = i.split("/")
                            .map {
                                it.trim().toInt()
                            }.toIntArray()

                        indices.add(vertices.size)
                        if (x.size == 2) {
                            vertices.add(Vertex(positions[x[0] - 1], textures[x[1] - 1]))
                        } else {
                            vertices.add(Vertex(positions[x[0] - 1], textures[x[1] - 1], normals[x[2] - 1]))
                        }
                    }
                }
            }
        }

        return MeshInfo(vertices.toTypedArray(), indices.toTypedArray())
    }

    fun loadObjAsMesh(stream: String): Mesh {
        val meshInfo = loadObjAsMeshInfo(stream)

        return Mesh(meshInfo.vertices, meshInfo.indices)
    }

    fun loadObjAsMeshName(name: String): Mesh {
        return loadObjAsMesh(ResourceManager.loadResourceAsString(ResourceType.MODEL, "$name.obj"))
    }

    fun loadObjAsMeshInfoName(name: String): MeshInfo {
        return loadObjAsMeshInfo(ResourceManager.loadResourceAsString(ResourceType.MODEL, "$name.obj"))
    }


    fun loadObjAsObject(name: String): ObjInfo {
        val mesh = loadObjAsMesh(ResourceManager.loadResourceAsString(ResourceType.MODEL, "$name.obj"))

        var minX = Float.MAX_VALUE
        var minY = Float.MAX_VALUE
        var minZ = Float.MAX_VALUE
        var maxX = Float.MIN_VALUE
        var maxY = Float.MIN_VALUE
        var maxZ = Float.MIN_VALUE

        for (vertex in mesh.vertices) {
            if (vertex.position.x < minX) {
                minX = vertex.position.x
            }
            if (vertex.position.y < minY) {
                minY = vertex.position.y
            }
            if (vertex.position.z < minZ) {
                minZ = vertex.position.z
            }

            if (vertex.position.x > maxX) {
                maxX = vertex.position.x
            }
            if (vertex.position.y > maxY) {
                maxY = vertex.position.y
            }
            if (vertex.position.z > maxZ) {
                maxZ = vertex.position.z
            }
        }

        val mins = Vec3(minX, minY, minZ)
        val maxs = Vec3(maxX, maxY, maxZ)
        return ObjInfo(mesh, BoundingBox(mins, maxs), Material(Texture(name), ShaderProgram("frag.glsl", "vert.glsl")))
    }

    data class ObjInfo(val mesh: Mesh, val boundingBox: BoundingBox, val material: Material)

    data class MeshInfo(val vertices: Array<Vertex>, val indices: Array<Int>) {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as MeshInfo

            if (!vertices.contentEquals(other.vertices)) return false
            if (!indices.contentEquals(other.indices)) return false

            return true
        }

        override fun hashCode(): Int {
            var result = vertices.contentHashCode()
            result = 31 * result + indices.contentHashCode()
            return result
        }
    }
}
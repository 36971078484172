package dev.scorbett123.kotlinGame.engine.`object`

import com.danielgergely.cgmath.graphics.rotateMatrix
import com.danielgergely.cgmath.graphics.scaleMatrix
import com.danielgergely.cgmath.graphics.translateMatrix
import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.mat4.plus
import com.danielgergely.cgmath.mat4.times
import com.danielgergely.cgmath.mat4.timesAssign
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec3.plusAssign
import com.danielgergely.cgmath.vec3.yx
import kotlin.math.PI
import kotlin.math.max
import kotlin.math.sqrt

open class Object {

    open var position = Vec3(0.0f, 0.0f, 0.0f)
    open var rotation = Vec3(0.0f, 0.0f, 0f)
    var scale = Vec3(1f, 1f, 1f)

    open val positionMatrix: Mat4
    get() {
        val translate = translateMatrix(position)

        rotation.x = rotation.x % 360
        rotation.y = rotation.y % 360
        rotation.z = rotation.z % 360

        val rotate = if ((rotation.x == 0f) and (rotation.y == 0f) and (rotation.z == 0f)) {
            val xMat = rotateMatrix(rotation.x, Vec3(1f, 0f, 0f))
            val yMat = rotateMatrix(rotation.y, Vec3(0f, 1f, 0f))
            val zMat = rotateMatrix(rotation.z, Vec3(0f, 0f, 1f))

            yMat * zMat * xMat
        } else {
            val xMat = rotateMatrix(rotation.x, Vec3(1f, 0f, 0f))
            val yMat = rotateMatrix(rotation.y, Vec3(0f, 1f, 0f))
            val zMat = rotateMatrix(rotation.z, Vec3(0f, 0f, 1f))

            xMat * yMat * zMat
        }

        val scale = scaleMatrix(scale)

        return scale * rotate * translate
    }

    init {
        GameObjects.objects.add(this)
    }

    open fun update() {

    }
    fun length(x: Float, y: Float, z: Float): Float {
        return sqrt(x * x + y * y + z * z)
    }
}
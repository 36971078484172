package dev.scorbett123.kotlinGame.engine.`object`.objects

import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec3.minusAssign
import com.danielgergely.cgmath.vec3.plusAssign
import dev.scorbett123.kotlinGame.engine.MyMap
import dev.scorbett123.kotlinGame.engine.`object`.ObjLoader
import dev.scorbett123.kotlinGame.engine.`object`.Vertex
import dev.scorbett123.kotlinGame.engine.physics.BoundingBox
import dev.scorbett123.kotlinGame.engine.render.Material
import dev.scorbett123.kotlinGame.engine.render.Mesh
import dev.scorbett123.kotlinGame.engine.render.shaders.ShaderProgram
import dev.scorbett123.kotlinGame.engine.render.texture.Texture
import kotlin.math.PI
import kotlin.math.abs
import kotlin.math.cos
import kotlin.math.sin

object Piping {

    lateinit var pipingRenderObject: ClickableObject


    val straight = ObjLoader.loadObjAsMeshInfoName("pipes/straight")
    val leftTurn = ObjLoader.loadObjAsMeshInfoName("pipes/leftCurved")

    val crossroad = ObjLoader.loadObjAsMeshInfoName("pipes/crossroad")
    val tSplit = ObjLoader.loadObjAsMeshInfoName("pipes/tSplit")

    val terminus = ObjLoader.loadObjAsMeshInfoName("pipes/terminus")

    init {

    }

    fun loadPipes() {
        val vertices = ArrayList<Vertex>()
        val indices = ArrayList<Int>()

        for (x in MyMap.xMin..MyMap.xMax) {
            for (y in MyMap.yMin..MyMap.yMax) {
                if (MyMap.getIsPipe(x, y)) {
                    val nextTo = ArrayList<Boolean>()
                    nextTo.add(MyMap.isPipeConnection(x, y + 1))
                    nextTo.add(MyMap.isPipeConnection(x + 1, y))
                    nextTo.add(MyMap.isPipeConnection(x, y - 1))
                    nextTo.add(MyMap.isPipeConnection(x - 1, y))

                    val amountNextTo = nextTo.count {
                        it
                    }

                    if (amountNextTo == 4) {
                        addSection(0f.toRadians(), Vec2(x.toFloat(), y.toFloat()), crossroad, vertices, indices)
                    } else if (amountNextTo == 3) {
                        val sideWithoutPipe = nextTo.indexOf(false) - 1
                        addSection((-90f).toRadians() * sideWithoutPipe, Vec2(x.toFloat(), y.toFloat()), tSplit, vertices, indices)
                    } else if (amountNextTo == 2){
                        val isStraight = (nextTo[0] && nextTo[2]) || (nextTo[1] && nextTo[3])
                        if (isStraight) {
                            if (nextTo[0] && nextTo[2]) {
                                addSection(90f.toRadians(), Vec2(x.toFloat(), y.toFloat()), straight, vertices, indices)
                            } else {
                                addSection(0f.toRadians(), Vec2(x.toFloat(), y.toFloat()), straight, vertices, indices)
                            }
                        } else {
                            // we do a turn
                            if (nextTo[3] && nextTo[0]) {
                                addSection(-90f.toRadians(), Vec2(x.toFloat(), y.toFloat()), leftTurn, vertices, indices)
                            } else if (nextTo[2] && nextTo[1]) {
                                addSection(-270f.toRadians(), Vec2(x.toFloat(), y.toFloat()), leftTurn, vertices, indices)
                            } else if (nextTo[2] && nextTo[3]) {
                                addSection(0f, Vec2(x.toFloat(), y.toFloat()), leftTurn, vertices, indices)
                            } else if (nextTo[1] && nextTo[0]) {
                                addSection(180f.toRadians(), Vec2(x.toFloat(), y.toFloat()), leftTurn, vertices, indices)
                            }
                        }
                    } else {
                        val side = nextTo.indexOf(true) + 1

                        addSection(-90f.toRadians() * side,  Vec2(x.toFloat(), y.toFloat()), terminus, vertices, indices)
                    }
                }
            }
        }

        val material = Material(Texture("pipe"), ShaderProgram("frag.glsl", "vert.glsl"))

        pipingRenderObject = ClickableObject(Mesh(vertices.toTypedArray(), indices.toTypedArray()), BoundingBox.EMPTY, material, 0, 0, 0, 0, -1, -1, "Piping", """This is the piping for the base. We always have at least 2 independent connections to all the buildings, aiming to have 3 on critical sections of pipes. This means that if one connection is destroyed there will continue to be a connection to the critical systems.""")
    }

    fun addSection(rotation: Float, translation: Vec2, section: ObjLoader.MeshInfo, vertices: ArrayList<Vertex>, indices: ArrayList<Int>) {
        for (vertex in section.vertices) {
            val v = vertex.copy()
            v.position -= Vec3(0.5f,0f, 0.5f) // we translate it so that the origin is the center

            val lastX = v.position.x
            v.position.x = v.position.x * cos(rotation) - v.position.z * sin(rotation)
            v.position.z = lastX * sin(rotation) + v.position.z * cos(rotation)

            v.position += Vec3(0.5f,0f, 0.5f) // we translate it so that the origin is back in the corner


            v.position += Vec3(translation.x, 0f, translation.y)
            vertices.add(v)
        }

        val current = indices.size
        for (index in section.indices) {
            indices.add(current + index)
        }
    }

    fun Float.toRadians(): Float {
        return ((this / 180) * PI).toFloat()
    }
}
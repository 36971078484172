package dev.scorbett123.kotlinGame.engine.gui

import com.danielgergely.cgmath.graphics.scaleMatrix
import com.danielgergely.cgmath.graphics.translateMatrix
import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.mat4.times
import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec4.Vec4
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ClickEvent
import dev.scorbett123.kotlinGame.engine.IO.callbacks.ScrollEvent

abstract class Component {
    abstract var x: Int
    abstract var y: Int

    abstract var width: Int
    abstract var height: Int

    abstract var fillColour: Vec4
    abstract var borderColour: Vec4
    var lastShowTime: Long = 0

    open fun tick() {

    }

    open fun prerender() {

    }

    open fun postrender() {

    }

    open fun onClick(event: ClickEvent) {

    }

    open fun onScroll(event: ScrollEvent) {

    }

    open fun wantsFullControl(): Boolean {
        return false
    }

    fun getTranslateMatrix(): Mat4 {
        return translateMatrix(Vec3(x.toFloat(), y.toFloat(), 0.toFloat()))
    }

    fun getMatrix(): Mat4 {
        val translate = translateMatrix(Vec3(x.toFloat(), y.toFloat(), 0.toFloat()))
        val scale = scaleMatrix(width.toFloat(), height.toFloat(), 1f)

        return scale * translate
    }

}
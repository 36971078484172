package dev.scorbett123.kotlinGame.platformSpecific

// Common
enum class LogLevel {
    DEBUG, WARN, ERROR
}

internal expect fun writeLogMessage(vararg message: Any, logLevel: LogLevel)

fun logDebug(vararg message: Any) = writeLogMessage(*message, logLevel = LogLevel.DEBUG)
fun logWarn(vararg message: Any) = writeLogMessage(*message,logLevel = LogLevel.WARN)
fun logError(vararg message: Any) = writeLogMessage(*message, logLevel = LogLevel.ERROR)
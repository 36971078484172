package dev.scorbett123.kotlinGame.engine.render

import dev.scorbett123.kotlinGame.engine.`object`.Vertex
import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.platformSpecific.WebGLVertexArrayObject
import org.khronos.webgl.Float32Array
import org.khronos.webgl.Int32Array
import org.khronos.webgl.WebGLBuffer
import org.khronos.webgl.WebGLRenderingContext

actual class Mesh actual constructor(
    actual val vertices: Array<Vertex>,
    actual val indices: Array<Int>
) {
    val vao: WebGLVertexArrayObject?
    val vbo: WebGLBuffer?
    val ebo: WebGLBuffer?
    init {
        var vertexList = ArrayList<Float>()

        for (vertex in vertices) {
            vertexList = vertex.write(vertexList)
        }

        vao = GL.webgl.createVertexArray()

        GL.webgl.bindVertexArray(vao)

        vbo = GL.webgl.createBuffer()
        ebo = GL.webgl.createBuffer()


        GL.webgl.bindBuffer(GL.ARRAY_BUFFER, vbo)

        GL.webgl.bufferData(GL.ARRAY_BUFFER, Float32Array(vertexList.toTypedArray()), GL.STATIC_DRAW)

        GL.webgl.vertexAttribPointer(0, 3, GL.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 0)
        GL.webgl.enableVertexAttribArray(0)

        GL.webgl.vertexAttribPointer(1, 2, GL.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 3 * Float32Array.BYTES_PER_ELEMENT)
        GL.webgl.enableVertexAttribArray(1)

        GL.webgl.vertexAttribPointer(2, 3, GL.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 5 * Float32Array.BYTES_PER_ELEMENT)
        GL.webgl.enableVertexAttribArray(2)

        GL.webgl.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, ebo)

        GL.webgl.bufferData(GL.ELEMENT_ARRAY_BUFFER, Int32Array(indices.toIntArray().toTypedArray()), GL.STATIC_DRAW)

        GL.webgl.bindBuffer(GL.ARRAY_BUFFER, null)

        GL.webgl.bindVertexArray(null)
    }

    actual fun apply() {
        GL.webgl.bindVertexArray(vao)
    }

}
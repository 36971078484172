package dev.scorbett123.kotlinGame.platformSpecific

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLCanvasElement
import org.khronos.webgl.WebGLRenderingContext as GLC

actual object GL {
    val canvas : HTMLCanvasElement = document.getElementById("webgl-canvas") as HTMLCanvasElement
    var webgl = getContext(canvas)

    init {
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight

        webgl.viewport(0, 0, canvas.width, canvas.height)
    }

    actual val COLOR_BUFFER_BIT: Int
        get() = GLC.COLOR_BUFFER_BIT
    actual val DEPTH_BUFFER_BIT: Int
        get() = GLC.DEPTH_BUFFER_BIT
    actual val DEPTH_TEST: Int
        get() = GLC.DEPTH_TEST
    actual val SCISSOR_TEST: Int
        get() = GLC.SCISSOR_TEST
    actual val VERTEX_SHADER: Int
        get() = GLC.VERTEX_SHADER
    actual val FRAGMENT_SHADER: Int
        get() = GLC.FRAGMENT_SHADER
    actual val COMPILE_STATUS: Int
        get() = GLC.COMPILE_STATUS
    actual val ARRAY_BUFFER: Int
        get() = GLC.ARRAY_BUFFER
    actual val STATIC_DRAW: Int
        get() = GLC.STATIC_DRAW
    actual val ELEMENT_ARRAY_BUFFER: Int
        get() = GLC.ELEMENT_ARRAY_BUFFER
    actual val TRIANGLES: Int
        get() = GLC.TRIANGLES
    actual val FLOAT: Int
        get() = GLC.FLOAT
    actual val UNSIGNED_INT: Int
        get() = GLC.UNSIGNED_INT

    actual val BLEND: Int
        get() = GLC.BLEND
    actual val SRC_ALPHA: Int
        get() = GLC.SRC_ALPHA
    actual val ONE_MINUS_SRC_ALPHA: Int
        get() = GLC.ONE_MINUS_SRC_ALPHA


    actual fun enable(toEnable: Int) {
        webgl.enable(toEnable)

    }

    actual fun postRender() {

    }

    actual fun clearColour(r: Float, g: Float, b: Float, a: Float) {
        webgl.clearColor(r, g, b, a)
    }

    actual fun clear(toClear: Int) {
        webgl.clear(toClear)
    }


    actual fun drawElements(method: Int, count: Int, element_type: Int) {
        webgl.drawElements(method, count, element_type, 0)
    }

    actual fun viewport(x: Int, y: Int, w: Int, h: Int) {
        webgl.viewport(x, y, w, h)
    }

    actual fun blendFunc(type: Int, what: Int) {
        webgl.blendFunc(type, what)
    }

    actual fun scissor(x: Int, y: Int, w: Int, h: Int) {
        webgl.scissor(x, y, w, h)
    }

    actual fun disable(toDisable: Int) {
        webgl.disable(toDisable)
    }

}
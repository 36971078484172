package dev.scorbett123.kotlinGame.engine.IO.callbacks

import com.danielgergely.cgmath.vec2.Vec2

data class ClickEvent(val position: Vec2, val action: Action) {

    enum class Action {
        PRESS,
        RELEASE
    }
}
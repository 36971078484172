package dev.scorbett123.kotlinGame.game.objects

import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.vec3.Vec3
import dev.scorbett123.kotlinGame.engine.`object`.ObjLoader
import dev.scorbett123.kotlinGame.engine.`object`.RenderObject
import dev.scorbett123.kotlinGame.engine.physics.BoundingBox
import dev.scorbett123.kotlinGame.engine.render.Material
import dev.scorbett123.kotlinGame.engine.render.Mesh
import kotlin.random.Random

class Terrain(baseMesh: Mesh, baseMaterial: Material): RenderObject(baseMesh, BoundingBox(Vec3(0f,0f,0f), Vec3(0f,0f,0f)), baseMaterial) {
    val rocks = ArrayList<RenderObject>()

    override fun render(projectionMatrix: Mat4) {
        super.render(projectionMatrix)

//        rocks.forEach {
//            it.render(projectionMatrix)
//        }
    }

    init {
        this.position = Vec3(0f, -0.1f, 0f)
    }

    companion object {
        val ROCKS_TO_ADD = 100
        private val objInfo = ObjLoader.loadObjAsObject("terrain")
        val INSTANCE: Terrain = Terrain(objInfo.mesh, objInfo.material)

        val rock: RenderObject by lazy {
            val obInfo = ObjLoader.loadObjAsObject("rocks/rock1")
            return@lazy RenderObject(obInfo.mesh, obInfo.boundingBox, obInfo.material, false)
        }

        fun updateRocks() {
            for (i in 0 until ROCKS_TO_ADD) {
                val rock = rock.copy(false)

                rock.position = Random.randomPosition(this.objInfo.boundingBox)
                rock.rotation = Random.randomRotation()

                INSTANCE.rocks.add(rock)
            }
        }

        fun Random.randomPosition(within: BoundingBox): Vec3 {
            val x = (within.minX + (within.maxX - within.minX) * Random.nextDouble()).toFloat()
            val y = 0.1f
            val z = (within.minZ + (within.maxZ - within.minZ) * Random.nextDouble()).toFloat()
            return Vec3(x, y, z)
        }

        fun Random.randomRotation(): Vec3 {
            val x = 0f
            val y = Random.nextInt(360)
            val z = Random.nextInt(360)

            return Vec3(x.toFloat(), y.toFloat(), z.toFloat())
        }
    }


}
package dev.scorbett123.kotlinGame.engine.render.texture

import dev.scorbett123.kotlinGame.engine.ResourceManager
import dev.scorbett123.kotlinGame.engine.ResourceType
import dev.scorbett123.kotlinGame.platformSpecific.GL
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLTexture
import org.w3c.dom.Image


actual class Image actual constructor(textureName: String, type: String) {
    val texture: WebGLTexture? = GL.webgl.createTexture()
    val image = Image()

    init {
        GL.webgl.bindTexture(WebGLRenderingContext.TEXTURE_2D, texture)

        val level = 0
        val internalFormat = WebGLRenderingContext.RGBA
        val width = 1
        val height = 1
        val border = 0
        val srcFormat = WebGLRenderingContext.RGBA
        val srcType = WebGLRenderingContext.UNSIGNED_BYTE
        val pixel = Uint8Array(byteArrayOf(211.toByte(), 42, 255.toByte(), 255.toByte()).toTypedArray())  // opaque blue
        GL.webgl.texImage2D(
            WebGLRenderingContext.TEXTURE_2D, level, internalFormat,
            width, height, border, srcFormat, srcType,
            pixel)


        image.onload = {
            GL.webgl.bindTexture(WebGLRenderingContext.TEXTURE_2D, texture)
            GL.webgl.texImage2D(
                WebGLRenderingContext.TEXTURE_2D, level, internalFormat,
                srcFormat, srcType, image)

            GL.webgl.texParameteri(WebGLRenderingContext.TEXTURE_2D, WebGLRenderingContext.TEXTURE_WRAP_S, WebGLRenderingContext.REPEAT) // Set texture wrapping to GL_REPEAT (default wrapping method)
            GL.webgl.texParameteri(WebGLRenderingContext.TEXTURE_2D, WebGLRenderingContext.TEXTURE_WRAP_T, WebGLRenderingContext.REPEAT)
            // Set texture filtering parameters
            GL.webgl.texParameteri(WebGLRenderingContext.TEXTURE_2D, WebGLRenderingContext.TEXTURE_MIN_FILTER, WebGLRenderingContext.LINEAR)
            GL.webgl.texParameteri(WebGLRenderingContext.TEXTURE_2D, WebGLRenderingContext.TEXTURE_MAG_FILTER, WebGLRenderingContext.LINEAR)
            GL.webgl.generateMipmap(WebGLRenderingContext.TEXTURE_2D)
        }
        image.src = "/texture/$textureName/$type.png"
    }

    actual fun bind(slot: Int) {
        GL.webgl.activeTexture(WebGLRenderingContext.TEXTURE0 + slot)
        GL.webgl.bindTexture(WebGLRenderingContext.TEXTURE_2D, texture)
    }

    actual val id: Int
        get() = TODO("Not yet implemented")
    actual val width: Int
        get() = image.width
    actual val height: Int
        get() = image.height

    actual operator fun get(x: Int, y: Int): Int {
        TODO("Not yet implemented")
    }

}
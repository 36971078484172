package dev.scorbett123.kotlinGame.engine.`object`

import com.danielgergely.cgmath.graphics.lookAt
import com.danielgergely.cgmath.graphics.rotateMatrix
import com.danielgergely.cgmath.graphics.scaleMatrix
import com.danielgergely.cgmath.graphics.translateMatrix
import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.mat4.plus
import com.danielgergely.cgmath.mat4.times
import com.danielgergely.cgmath.vec3.Vec3
import com.danielgergely.cgmath.vec3.plus
import com.danielgergely.cgmath.vec3.plusAssign
import com.danielgergely.cgmath.vec3.times
import dev.scorbett123.kotlinGame.engine.IO.IO
import dev.scorbett123.kotlinGame.platformSpecific.logDebug
import kotlin.math.PI
import kotlin.math.abs
import kotlin.math.max

object Camera: Object() {

    val lookAt = Vec3(0f, -1f,-0.5f)
    var trackObject: RenderObject? = null

    override val positionMatrix: Mat4
        get() = lookAt(position, trackObject?.position ?: (position + lookAt), Vec3(0f, 1f, 0f))

    val delta get() = 0.1f * position.y / 10
    init {
        position = Vec3(0f, 150f, 340f)
    }

    override fun update() {
        if (IO.isKeyDown("a")) {
            position.x -= delta
        }
        if (IO.isKeyDown("w")) {
            position.z -= delta
        }
        if (IO.isKeyDown("d")) {
             position.x += delta
        }
        if (IO.isKeyDown("s")) {
            position.z += delta
        }
        if (IO.isKeyDown("shift")) {
            if (position.y < 1000 - delta - 5) {
                position.y += delta
            }
        }
        if (IO.isKeyDown("control")) {
            if (delta > 0.01) {
                position.y += -delta
            }
        }

        if (abs(position.x) > 100) {
            position.x = 100 * position.x / abs(position.x)
        }

        if (abs(position.z) > 500) {
            position.z = 500 * position.z / abs(position.z)
        }


//        if (IO.isKeyDown("r")) {
//            lookAt.z += delta
//        }
//        if (IO.isKeyDown("f")) {
//            lookAt.z -= delta
//        }

//        if (IO.isKeyDown("q")) {
//            lookAt.x += delta
//        }
//        if (IO.isKeyDown("e")) {
//            lookAt.x -= delta
//        }
    }
}
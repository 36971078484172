package dev.scorbett123.kotlinGame.engine.`object`

import dev.scorbett123.kotlinGame.engine.MyMap
import dev.scorbett123.kotlinGame.engine.ResourceManager
import dev.scorbett123.kotlinGame.engine.ResourceType
import dev.scorbett123.kotlinGame.engine.`object`.objects.ClickableObject
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

@Serializable
class ClickableObjectInfo(val renderObjectName: String, val displayName: String=renderObjectName,  val width: Int, val height: Int, val pipeConnectionPointX: Int, val pipeConnectionPointY: Int, val description: String) {

    val objectInfo by lazy { ObjLoader.loadObjAsObject(renderObjectName) }
}

@Serializable
data class MapObjectInfo(val name: String, val x: Int, val y: Int) {

}

@Serializable
data class Vec2(val x: Int, val y: Int)

@Serializable
data class DataMap(val objects: ArrayList<MapObjectInfo>, val pipes: ArrayList<Vec2>)

object JsonLoader {

    val cachedInfo: HashMap<String, ClickableObjectInfo> = HashMap()

    fun loadInfo(objectName: String): ClickableObjectInfo {
        if (cachedInfo.containsKey(objectName)) {
            return cachedInfo[objectName]!! // this will never be null so using force not null is fine
        }
        val string = ResourceManager.loadResourceAsString(ResourceType.OBJECT, "$objectName.json")
        return Json.decodeFromString(string)
    }

    fun loadMap() {
        val string = ResourceManager.loadResourceAsString(ResourceType.MAP, "map.json")
        val map = Json.decodeFromString<DataMap>(string)

        map.objects.forEach {
            val info = loadInfo(it.name)

            val clickObject = ClickableObject(info.objectInfo.mesh, info.objectInfo.boundingBox, info.objectInfo.material, it.x, it.y, info.width, info.height, info.pipeConnectionPointX, info.pipeConnectionPointY, info.displayName, info.description)

            MyMap.addObject(clickObject)
        }

        map.pipes.forEach {
            MyMap.setIsPipe(it.x, it.y, true)
        }
    }
}
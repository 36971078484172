package dev.scorbett123.kotlinGame.engine.gui

import com.danielgergely.cgmath.graphics.scaleMatrix
import com.danielgergely.cgmath.graphics.translateMatrix
import com.danielgergely.cgmath.mat4.Mat4
import com.danielgergely.cgmath.mat4.times
import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3
import dev.scorbett123.kotlinGame.engine.`object`.Vertex
import dev.scorbett123.kotlinGame.engine.render.Mesh
import dev.scorbett123.kotlinGame.engine.render.shaders.ShaderProgram
import dev.scorbett123.kotlinGame.engine.render.texture.Image
import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.platformSpecific.logDebug

object FontRenderer {
//
//    val charWidths = IntArray(256)
//    val untilStart = IntArray(256)
    val fontImage = Image("font", "font")
    val boldImage = Image("font", "bold")
    val textShader = ShaderProgram("textFrag.glsl", "guiVert.glsl")
    val scrollBarShader = ShaderProgram("scrollBar.glsl", "guiVert.glsl")

    val scrollBar = Mesh(arrayOf(
        Vertex(Vec3(0f, 0f, 0f), Vec2(1f, 1f)),
        Vertex(Vec3(0f, 1f, 0f), Vec2(1f, 0f)),
        Vertex(Vec3(1f, 1f, 0f), Vec2(0f, 0f)),
        Vertex(Vec3(1f, 0f, 0f), Vec2(0f, 1f))
    ),
        arrayOf(0, 1, 2,
            0, 2, 3)
    )

    fun renderText(textPanel: TextPanel, parent: Component) {
        textShader.apply()

        textPanel.bakedMesh.apply()

        if (textPanel.bold) {
            boldImage.bind(0)
        } else {
            fontImage.bind(0)
        }

        GL.enable(GL.SCISSOR_TEST)

        GL.scissor((parent.x + GUI.cornerRadius).toInt(), (parent.y + GUI.cornerRadius).toInt(), textPanel.width, textPanel.height)

        textShader.setMatrix4f("perspective", GuiRenderer.perspective)
        textShader.setMatrix4f("object", textPanel.getTranslateMatrix())
        textShader.setVec4("textColour", textPanel.textColour)

        GL.drawElements(GL.TRIANGLES, textPanel.bakedMesh.indices.size, GL.UNSIGNED_INT)

        GL.disable(GL.SCISSOR_TEST)

        scrollBarShader.apply()

        scrollBar.apply()

        scrollBarShader.setMatrix4f("perspective", GuiRenderer.perspective)
        scrollBarShader.setMatrix4f("object", textPanel.getScrollBarTransformationMatrix())

        val barSize = textPanel.height / textPanel.actualHeight
        val start = textPanel.scroll / textPanel.actualHeight

        scrollBarShader.setFloat("start", start)
        scrollBarShader.setFloat("stop", start + barSize)

        GL.drawElements(GL.TRIANGLES, scrollBar.indices.size, GL.UNSIGNED_INT)
    }

    private fun TextPanel.getScrollBarTransformationMatrix(): Mat4 {
        val x = parent.x + parent.width - GUI.cornerRadius - 10
        val y = parent.y.toFloat() + GUI.cornerRadius

        val width = 10f
        val height = parent.height.toFloat() - GUI.cornerRadius * 2

        val translate = translateMatrix(Vec3(x, y, 0f))
        val scale = scaleMatrix(width, height, 1f)

        return scale * translate
    }
}
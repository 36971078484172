package dev.scorbett123.kotlinGame.engine.render

import dev.scorbett123.kotlinGame.engine.render.texture.Texture
import dev.scorbett123.kotlinGame.engine.render.shaders.ShaderProgram

class Material(val texture: Texture, val shaderProgram: ShaderProgram) {

    fun apply() {
        texture.bind()
        shaderProgram.apply()
    }
}
package dev.scorbett123.kotlinGame.engine.render.texture

actual class Texture actual constructor(name: String) {
    val colourImage: Image = Image(name, "colour")

    init {

    }

    actual fun bind() {
        colourImage.bind(0)
    }
}
package dev.scorbett123.kotlinGame.engine.render

import com.danielgergely.cgmath.graphics.perspective
import com.danielgergely.cgmath.graphics.rotateMatrix
import com.danielgergely.cgmath.mat4.Mat4
import dev.scorbett123.kotlinGame.engine.`object`.Camera
import dev.scorbett123.kotlinGame.engine.`object`.RenderObject
import dev.scorbett123.kotlinGame.platformSpecific.GL
import dev.scorbett123.kotlinGame.platformSpecific.logDebug

object Renderer {

    fun render(ob: RenderObject, projectionMatrix: Mat4) {
        ob.material.apply()

        ob.material.shaderProgram.setMatrix4f("model", ob.positionMatrix)
        ob.material.shaderProgram.setMatrix4f("view", Camera.positionMatrix)


        ob.material.shaderProgram.setMatrix4f("projection",projectionMatrix)

        ob.mesh.apply()

        GL.drawElements(GL.TRIANGLES, ob.mesh.indices.size, GL.UNSIGNED_INT)
    }
}
package dev.scorbett123.kotlinGame.engine.`object`

import com.danielgergely.cgmath.vec2.Vec2
import com.danielgergely.cgmath.vec3.Vec3

data class Vertex(val position: Vec3, val textureCoord: Vec2, val normal: Vec3) {

    constructor(position: Vec3, textureCoord: Vec2): this(position, textureCoord, Vec3(0f,0f,0f))
    fun write(list: ArrayList<Float>): ArrayList<Float> {
        list.add(position.x)
        list.add(position.y)
        list.add(position.z)

        list.add(textureCoord.x)
        list.add(textureCoord.y)

        list.add(normal.x)
        list.add(normal.y)
        list.add(normal.z)
        return list
    }

    fun copy(): Vertex {
        return Vertex(this.position.copy(), this.textureCoord.copy(), this.normal.copy())
    }

    fun Vec3.copy(): Vec3 {
        return Vec3(this.x, this.y, this.z)
    }

    fun Vec2.copy(): Vec2 {
        return Vec2(this.x, this.y)
    }
}